























import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';

export default defineComponent({
  name: 'HowtoSection',
  setup(props, { emit }) {
    const howtoArea: Ref<any | null> = ref(null);

    onMounted(() => {
      emit('emitAddIntersection', howtoArea.value);
    });

    return { i18nTxt, howtoArea };
  }
});
