



































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';

export default defineComponent({
  name: 'InfoSection',
  setup(props, { emit }) {
    const infoArea: Ref<any | null> = ref(null);

    const detailToggleHandler = (idx: number) => {
      if (idx === 0) {
        emit('infoShowClick', 0);
      } else {
        emit('infoShowClick', 1);
      }
    };

    onMounted(() => {
      emit('emitAddIntersection', infoArea.value);
    });

    return {
      i18nTxt,
      infoArea,
      detailToggleHandler
    };
  }
});
