




















































































































































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import { isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'DemoSection',
  setup(props, { emit }) {
    const isPCCheck: boolean = isPC();
    const photoListArea: Ref<any | null> = ref(null);
    const colorArea: Ref<any | null> = ref(null);
    const demoArea: Ref<any | null> = ref(null);
    const patternArea: Ref<any | null> = ref(null);
    const categoryArea: Ref<any | null> = ref(null);
    const neckLineArea: Ref<any | null> = ref(null);
    const isDisabled = ref(true);
    const listClass = ref('');
    const listAreaClass = ref('demo1');
    const selectTagList = document.getElementsByClassName('select_tag');

    const selectTagShowHandler = (index: number) => {
      for (let i = 0; i < selectTagList.length; i++) {
        if (i < index) {
          (selectTagList.item(i) as HTMLElement).classList.add('show_tag');
        } else {
          (selectTagList.item(i) as HTMLElement).classList.remove('show_tag');
        }
      }
    };

    // 색상 선택
    const colorSelectHandler = (e: Event) => {
      const target = (e.target as HTMLElement).parentElement!;
      if (target.className.indexOf('highlight') < 0) return;
      patternArea.value.classList.add('show');
      if (isPCCheck) {
        target.classList.remove('highlight');
        target.classList.add('on');
        listClass.value = 'sel_color';
        listAreaClass.value = 'demo1';
      } else {
        isDisabled.value = false;
        listAreaClass.value = 'demo2';
        colorArea.value.classList.add('hide');
        patternArea.value.classList.add('mobile');
        selectTagShowHandler(1);
      }
    };

    // 패턴 선택
    const patternSelectHandler = (e: Event) => {
      const target = (e.target as HTMLElement).parentElement!;
      if (target.className.indexOf('highlight') < 0) return;
      categoryArea.value.classList.add('show');
      if (isPCCheck) {
        target.classList.remove('highlight');
        target.classList.add('on');
        listClass.value = 'sel_color pattern';
        listAreaClass.value = 'demo1';
      } else {
        listAreaClass.value = 'demo3';
        patternArea.value.classList.remove('show');
        categoryArea.value.classList.add('mobile');
        selectTagShowHandler(2);
      }
    };

    // 카테고리 선택
    const cateogrySelectHandler = (e: Event) => {
      const target = (e.target as HTMLElement).parentElement!;
      if (target.className.indexOf('highlight') < 0) return;
      neckLineArea.value.classList.add('show');
      if (isPCCheck) {
        target.classList.remove('highlight');
        target.classList.add('on');
        listClass.value = 'sel_color pattern category';
        listAreaClass.value = 'demo1';
      } else {
        listAreaClass.value = 'demo4';
        categoryArea.value.classList.remove('show');
        neckLineArea.value.classList.add('mobile');
        selectTagShowHandler(3);
      }
    };

    // 넥라인 선택
    const neckLineSelectHandler = (e: Event) => {
      const target = (e.target as HTMLElement).parentElement!;
      if (target.className.indexOf('highlight') < 0) return;
      target.classList.remove('highlight');
      target.classList.add('on');
      listClass.value = '';
      listAreaClass.value = 'demo5';
      selectTagShowHandler(4);
    };

    // 모바일 다시 경험하기 버튼
    const resetButtonHandler = () => {
      if (isDisabled.value) return;
      isDisabled.value = true;
      listClass.value = '';
      listAreaClass.value = 'demo1';
      (neckLineArea.value as HTMLElement).children
        .item(1)
        ?.childNodes.forEach((value) => {
          if ((value as HTMLElement).className.indexOf('on') > -1) {
            (value as HTMLElement).classList.remove('on');
            (value as HTMLElement).classList.add('highlight');
          }
        });

      colorArea.value.classList.remove('hide');
      patternArea.value.classList.remove('show');
      categoryArea.value.classList.remove('show');
      neckLineArea.value.classList.remove('show');
      selectTagShowHandler(0);
    };

    onMounted(() => {
      emit('emitAddIntersection', demoArea.value);
      isDisabled.value = true;
    });

    return {
      i18nTxt,
      isPCCheck,
      photoListArea,
      colorArea,
      demoArea,
      patternArea,
      categoryArea,
      neckLineArea,
      isDisabled,
      listClass,
      listAreaClass,
      colorSelectHandler,
      patternSelectHandler,
      cateogrySelectHandler,
      neckLineSelectHandler,
      resetButtonHandler
    };
  }
});
