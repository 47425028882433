













































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import {
  DemoSection,
  HowtoSection,
  InfoSection,
  SpecSection
} from '@/components/index';
import Demo from '@/components/services/Demo.vue';
import QModal from '@/components/services/QModal.vue';

export default defineComponent({
  name: 'AITag',
  components: {
    Demo,
    InfoSection,
    DemoSection,
    SpecSection,
    HowtoSection,
    QModal
  },
  setup(props, { emit }) {
    const titleArea: Ref<any | null> = ref(null);
    const isInfoShow_0 = ref(false);
    const isInfoShow_1 = ref(false);

    const io = new IntersectionObserver(
      (entries) => {
        entries.map((entry) => {
          if (entry.isIntersecting) entry.target.classList.add('start');
        });
      },
      { threshold: 0.2 }
    );

    const addIntersection = (value: any) => {
      io.observe(value);
    };

    onMounted(() => {
      emit('emitIsHomeFocus', false);

      addIntersection(titleArea.value);
    });

    const detailToggleHandler = (idx: number) => {
      if (idx === 0) {
        isInfoShow_0.value = false;
      } else {
        isInfoShow_1.value = false;
      }
    };

    const handleInfoShowClick = (idx: number) => {
      if (idx === 0) {
        isInfoShow_0.value = true;
      } else {
        isInfoShow_1.value = true;
      }
    };

    return {
      i18nTxt,
      titleArea,
      addIntersection,
      handleInfoShowClick,
      detailToggleHandler,
      isInfoShow_0,
      isInfoShow_1
    };
  }
});
